import React from 'react';
import {
    FaFacebook,
    FaInstagram,
    FaFacebookMessenger,
    FaTwitter,
    FaEnvelope,
    FaPhone,
    FaMapMarkerAlt
} from 'react-icons/fa';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import {animateScroll as scroll} from "react-scroll";

function Footer() {
    const currentYear = new Date().getFullYear();
    const location = useLocation();

    const handleHomeClick = () => {
        if (location.pathname === '/') {
            scroll.scrollToTop({ duration: 500, smooth: true });
        }
    };

    return (
        <footer className="relative z-10">
            <div className="bg-dark-accent py-12">
                <div className="container mx-auto px-4">
                    <div className="max-w-8xl mx-auto">
                        <div className="grid grid-cols-1 md:grid-cols-3 gap-12">
                            {/* Logo and Company Name */}
                            <div>
                                <RouterLink to="/" onClick={handleHomeClick}>
                                    <img src="/jls-c.webp" alt="Logo" className="h-[200px] mb-4"/>
                                </RouterLink>
                                {/*<p className="text-gray-300">Just in Live Stream, LLC</p>*/}
                            </div>

                            {/* Contact Information */}
                            <div>
                                <h4 className="font-bold text-lg mb-6 text-white">Contact</h4>
                                <ul className="space-y-6">
                                    <li>
                                        <div className="flex items-center space-x-3 mb-2">
                                            <span className="text-gray-300 font-semibold">Justin Strand</span>
                                        </div>
                                        <div className="ml-8 space-y-1">
                                            <a
                                                href="mailto:jstrand@justinlivestream.com"
                                                className="text-blue-400 hover:underline flex items-center space-x-2"
                                            >
                                                <FaEnvelope className="text-blue-400" size={16}/>
                                                <span>jstrand@justinlivestream.com</span>
                                            </a>
                                            <a
                                                href="tel:+17152434211"
                                                className="text-blue-400 hover:underline flex items-center space-x-2"
                                            >
                                                <FaPhone className="text-blue-400" size={16}/>
                                                <span>(715) 243-4211</span>
                                            </a>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="flex items-center space-x-3 mb-2">
                                            <span className="text-gray-300 font-semibold">Zachary Cunningham</span>
                                        </div>
                                        <div className="ml-8">
                                            <a
                                                href="mailto:zcunningham@justinlivestream.com"
                                                className="text-blue-400 hover:underline flex items-center space-x-2"
                                            >
                                                <FaEnvelope className="text-blue-400" size={16}/>
                                                <span>zcunningham@justinlivestream.com</span>
                                            </a>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="flex items-center space-x-3">
                                            <FaMapMarkerAlt className="text-gray-400" size={20}/>
                                            <span className="text-gray-300">1411 Cernohous Ave, Ste 340, New Richmond, WI 54017</span>
                                        </div>
                                    </li>
                                </ul>
                            </div>

                            {/* Social Media Links */}
                            <div>
                                <h4 className="font-bold text-lg mb-6 text-white">Follow Us</h4>
                                <div className="flex space-x-6">
                                    <a
                                        href="https://www.facebook.com/justinlivestream"
                                        className="hover:text-gray-300 transition-colors duration-300"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        <FaFacebook size={32}/>
                                    </a>
                                    <a
                                        href="https://www.instagram.com/just_in_live_stream/"
                                        className="hover:text-gray-300 transition-colors duration-300"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        <FaInstagram size={32}/>
                                    </a>
                                    <a
                                        href="https://m.me/justinlivestream"
                                        className="hover:text-gray-300 transition-colors duration-300"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        <FaFacebookMessenger size={32}/>
                                    </a>
                                    <a
                                        href="https://twitter.com/LiveStreamingNR"
                                        className="hover:text-gray-300 transition-colors duration-300"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        <FaTwitter size={32}/>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="bg-dark-card w-full py-4 text-center text-gray-400">
                <p>© {currentYear} Just in Live Stream. All rights reserved.</p>
            </div>
        </footer>
    );
}

export default Footer;
