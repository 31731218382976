import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

function Scroll() {
    const location = useLocation();

    useEffect(() => {
        // If there's a scroll target in the state, do nothing.
        if (!(location.state && location.state.scrollTo)) {
            window.scrollTo(0, 0);
        }
    }, [location]);

    return null;
}

export default Scroll;
