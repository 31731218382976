import React, { useState } from 'react';
import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom';
import { scroller, animateScroll as scroll } from 'react-scroll';
import { FaBars, FaTimes } from 'react-icons/fa';

function Header() {
    const [isOpen, setIsOpen] = useState(false);
    const location = useLocation();
    const navigate = useNavigate();

    const toggleMenu = () => setIsOpen(!isOpen);

    const handleHomeClick = () => {
        setIsOpen(false);
        if (location.pathname === '/') {
            scroll.scrollToTop({ duration: 500, smooth: true });
        }
    };

    const handleSectionClick = (section) => {
        setIsOpen(false);
        if (location.pathname !== '/') {
            navigate('/', { state: { scrollTo: section } });
        } else {
            scroller.scrollTo(section, { smooth: true, offset: -80, duration: 500 });
        }
    };

    return (
        <header className="bg-dark-card shadow-md fixed top-0 w-full z-20">
            <div className="container mx-auto px-4 py-4 flex justify-between items-center">
                {/* Logo */}
                <RouterLink to="/" onClick={handleHomeClick}>
                    <img src="/logo.webp" alt="Logo" className="h-[65px]" />
                </RouterLink>

                {/* Hamburger Icon (Mobile) */}
                <button className="lg:hidden text-white focus:outline-none" onClick={toggleMenu}>
                    {isOpen ? <FaTimes size={24} /> : <FaBars size={24} />}
                </button>

                {/* Desktop Navigation Links */}
                <nav className="hidden lg:flex items-center space-x-6">
                    <RouterLink to="/" className="text-white hover:text-gray-300" onClick={handleHomeClick}>
                        Home
                    </RouterLink>
                    <button
                        onClick={() => handleSectionClick('partners')}
                        className="text-white hover:text-gray-300 focus:outline-none"
                    >
                        Partners
                    </button>
                    <button
                        onClick={() => handleSectionClick('events')}
                        className="text-white hover:text-gray-300 focus:outline-none"
                    >
                        Events
                    </button>
                    {/*<RouterLink to="/admin" className="text-white hover:text-gray-300">*/}
                    {/*    Admin*/}
                    {/*</RouterLink>*/}
                </nav>
            </div>

            {/* Mobile Navigation Sidebar - starts under header */}
            <div
                className={`lg:hidden fixed top-[100px] right-0 bottom-0 w-3/4 bg-dark-bg z-30 transition-transform duration-500 transform ${
                    isOpen ? 'translate-x-0' : 'translate-x-full'
                }`}
            >
                <div className="p-6 flex flex-col justify-center space-y-8">
                    <RouterLink
                        to="/"
                        onClick={handleHomeClick}
                        className="text-white text-xl font-semibold pl-4"
                    >
                        Home
                    </RouterLink>
                    <button
                        onClick={() => handleSectionClick('partners')}
                        className="text-white text-xl font-semibold text-left pl-4 focus:outline-none"
                    >
                        Partners
                    </button>
                    <button
                        onClick={() => handleSectionClick('events')}
                        className="text-white text-xl font-semibold text-left pl-4 focus:outline-none"
                    >
                        Events
                    </button>
                    {/*<RouterLink*/}
                    {/*    to="/admin"*/}
                    {/*    onClick={() => setIsOpen(false)}*/}
                    {/*    className="text-white text-xl font-semibold text-left pl-4"*/}
                    {/*>*/}
                    {/*    Admin*/}
                    {/*</RouterLink>*/}
                </div>
            </div>
        </header>
    );
}

export default Header;
