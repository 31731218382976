import React, {useState, useEffect} from 'react';
import {useParams, useNavigate} from 'react-router-dom';
import axios from 'axios';
import {FaArrowLeft} from 'react-icons/fa';
import Calendar from '../components/Calendar';
import {Helmet} from "react-helmet";

function PartnerPage() {
    const {slug} = useParams();
    const [partner, setPartner] = useState(null);
    const navigate = useNavigate();
    const apiUrl = process.env.REACT_APP_API_URL || '';

    useEffect(() => {
        const fetchPartner = async () => {
            try {
                const response = await axios.get(`${apiUrl}/api/partners/slug/${slug}`);
                setPartner(response.data);
            } catch (error) {
                console.error('Error fetching partner:', error);
            }
        };
        fetchPartner();
    }, [slug]);

    if (!partner) {
        return (
            <div className="container mx-auto px-4 py-20 relative z-10 text-center">
                Loading...
            </div>
        );
    }

    // Mapping for custom banner images stored in your public directory.
    const partnerBannerImages = {
        'tiger-sports': '/partner_banners/tiger_sports.webp',
        'spartan-sports': '/partner_banners/spartan_sports.webp',
        'wws': '/partner_banners/western_wi_stars.webp',
        'cprha': '/partner_banners/cprha.webp',
        'crrha': '/partner_banners/crrha.webp',
        'volrha': '/partner_banners/volrha.webp',
        'krha': '/partner_banners/krha.webp',
        'ncrha': '/partner_banners/ncrha.webp',
        'ncrcha': '/partner_banners/ncrcha.webp',
        'crca': '/partner_banners/crca.webp',
        'rmrha': '/partner_banners/rmrha.webp',
        'mwrha': '/partner_banners/mwrha.webp',
        'ilrha': '/partner_banners/ilrha_banner.webp',
    };

    // Use a custom banner if available; otherwise fall back to partner.image_url.
    const bannerImage = partnerBannerImages[partner.slug] || partner.image_url;

    const handleBack = () => {
        navigate('/', {state: {scrollTo: 'partners'}});
    };

    // For these partners, we need to filter the calendar.
    const filteredPartners = [
        'Tiger Sports',
        'Spartan Sports',
        'Western WI Stars',
        'CPRHA',
        'CRRHA',
        'VOLRHA',
        'KRHA',
        'NCRHA',
        'NCRCHA',
        'CRCA',
        'RMRHA',
        'MWRHA',
        'ILRHA'
    ];
    const filterTag = filteredPartners.includes(partner.title) ? partner.title : null;

    return (
        <>
            <Helmet>
                <title>{partner.title} &#8226; Justin Live Stream</title>
                <meta
                    name="description"
                    content={
                        partner.description
                            ? partner.description
                            : 'Live Stream and Video Production.'
                    }
                />
            </Helmet>

            <div className="container mx-auto px-4 py-20 relative z-10">
                <button
                    onClick={handleBack}
                    className="flex items-center space-x-2 px-6 py-2 mb-6 bg-blue-600 hover:bg-blue-700 text-white rounded-full shadow-md transition duration-300"
                >
                    <FaArrowLeft className="w-5 h-5"/>
                    <span>Back to Partners</span>
                </button>

                {/* Partner name and description added for SEO */}
                <div className="text-center my-6">
                    <h1 className="text-4xl md:text-5xl font-bold text-white">
                        {partner.title}
                    </h1>
                    <p className="mt-4 text-base md:text-lg text-white">
                        {partner.description}
                    </p>
                </div>

                <div className="bg-dark-card p-4 rounded-lg flex items-center justify-center h-48 md:h-auto">
                    <img
                        src={bannerImage}
                        alt={partner.title}
                        className="max-h-full object-contain rounded"
                    />
                </div>

                {/* Render the calendar */}
                <div className="mt-10">
                    <Calendar filterTag={filterTag}/>
                </div>
                {/* Archives Section */}
                {partner.archives && partner.archives.length > 0 && (
                    <section className="mt-16">
                        <h2 className="text-3xl font-bold mb-6 text-center">Archives</h2>
                        <div className="space-y-8">
                            {partner.archives.map((archive) => (
                                <div key={archive.year} className="bg-dark-card p-6 rounded-lg shadow-md">
                                    <h3 className="text-2xl font-semibold mb-4">{archive.year}</h3>
                                    <ul className="list-disc pl-5 space-y-2">
                                        {archive.items.map((item) => (
                                            <li key={item.url}>
                                                <a
                                                    href={item.url}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    className="text-blue-400 hover:underline"
                                                >
                                                    {item.title}
                                                </a>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            ))}
                        </div>
                    </section>
                )}
            </div>
        </>
    );
}

export default PartnerPage;
