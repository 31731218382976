import React, { useState, useEffect } from 'react';
import axios from 'axios';

const TAG_OPTIONS = [
    "Tiger Sports",
    "Western WI Stars",
    "Spartan Sports",
    "CPRHA",
    "CRRHA",
    "VOLRHA",
    "KRHA",
    "NCRHA",
    "NCRCHA",
    "CRCA",
    "RMRHA",
    "MWRHA",
    "ILRHA"
];

// Helper function to convert a Date object for datetime-local input.
function toDateTimeLocalString(date) {
    const pad = (num) => (num < 10 ? '0' : '') + num;
    return (
        date.getFullYear() +
        '-' +
        pad(date.getMonth() + 1) +
        '-' +
        pad(date.getDate()) +
        'T' +
        pad(date.getHours()) +
        ':' +
        pad(date.getMinutes())
    );
}

// Reusable FileBrowser component.
function FileBrowser({ directory, onSelect, onClose }) {
    const [images, setImages] = useState([]);

    useEffect(() => {
        axios
            .get(`/api/images?dir=${directory}`)
            .then((res) => setImages(res.data))
            .catch((err) => console.error("Error fetching images:", err));
    }, [directory]);

    return (
        <div className="fixed inset-0 bg-black bg-opacity-70 flex items-center justify-center z-50">
            <div className="bg-dark-card p-6 rounded-lg w-1/2">
                <h2 className="text-xl font-bold mb-4">Select an Image</h2>
                <div className="grid grid-cols-3 gap-4">
                    {images.map((img, index) => (
                        <div
                            key={index}
                            className="cursor-pointer border rounded hover:opacity-75"
                            onClick={() => onSelect(img.url)}
                        >
                            <img
                                src={img.url}
                                alt={img.name}
                                className="w-full h-32 object-cover rounded"
                            />
                            <div className="text-center text-sm mt-1">{img.name}</div>
                        </div>
                    ))}
                </div>
                <button
                    onClick={onClose}
                    className="mt-4 inline-block bg-blue-600 hover:bg-blue-700 text-white px-4 py-2 rounded"
                >
                    Close
                </button>
            </div>
        </div>
    );
}

function EventManager() {
    const [events, setEvents] = useState([]);
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const limit = 6; // Maximum events per page
    const [filterTag, setFilterTag] = useState("");
    const [showPast, setShowPast] = useState(false);
    const [newlyAddedId, setNewlyAddedId] = useState(null);

    const [editing, setEditing] = useState(null);
    const [formData, setFormData] = useState({
        title: "",
        event_date: "",
        end_date: "",
        stream_url: "",
        event_type: "other", // "sports" or "other"
        tag: "",
        home_team: "",
        away_team: "",
        home_team_image: "",
        away_team_image: "",
        image_path: ""
    });

    // File browser state.
    const [fileBrowserOpen, setFileBrowserOpen] = useState(false);
    const [fileBrowserField, setFileBrowserField] = useState("");
    const [fileBrowserDir, setFileBrowserDir] = useState("");

    const fetchEvents = () => {
        let url = `/api/events?limit=${limit}&page=${page}`;
        if (filterTag) {
            url += `&tag=${encodeURIComponent(filterTag)}`;
        }
        if (showPast) {
            url += `&showPast=true`;
        }
        axios
            .get(url)
            .then((res) => {
                setEvents(res.data.events);
                setTotalPages(res.data.totalPages);
            })
            .catch((err) => console.error("Error fetching events", err));
    };

    useEffect(() => {
        fetchEvents();
    }, [page, filterTag, showPast]);

    // Retrieve token for protected endpoints.
    const token = localStorage.getItem('token');

    const handleFileUpload = async (e, field, directory) => {
        const file = e.target.files[0];
        if (file) {
            const fd = new FormData();
            fd.append('file', file);
            try {
                const res = await axios.post(`/api/upload?dir=${directory}`, fd, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        Authorization: `Bearer ${token}`
                    }
                });
                setFormData({ ...formData, [field]: res.data.url });
            } catch (error) {
                console.error("File upload error:", error);
            }
        }
    };

    const openFileBrowserForField = (field) => {
        setFileBrowserField(field);
        if (field === "home_team_image" || field === "away_team_image") {
            setFileBrowserDir("team_images");
        } else if (field === "image_path") {
            setFileBrowserDir("events");
        }
        setFileBrowserOpen(true);
    };

    const handleDelete = (id) => {
        if (window.confirm("Are you sure you want to delete this event?")) {
            axios.delete(`/api/events/${id}`, {
                headers: { Authorization: `Bearer ${token}` }
            })
                .then(() => {
                    const updatedEvents = events.filter((event) => event.id !== id);
                    if (updatedEvents.length === 0 && page > 1) {
                        setPage(page - 1);
                    } else {
                        fetchEvents();
                    }
                })
                .catch((err) => console.error("Delete error", err));
        }
    };

    const handleEdit = (event) => {
        setEditing(event);
        setFormData({
            title: event.title,
            event_date: toDateTimeLocalString(new Date(event.event_datetime)),
            end_date: event.end_datetime ? toDateTimeLocalString(new Date(event.end_datetime)) : "",
            stream_url: event.stream_url,
            event_type: event.event_type,
            tag: event.tag || "",
            home_team: event.home_team || "",
            away_team: event.away_team || "",
            home_team_image: event.home_team_image || "",
            away_team_image: event.away_team_image || "",
            image_path: event.image_path || ""
        });
        setNewlyAddedId(null);
    };

    const handleAdd = () => {
        setEditing(null);
        setFormData({
            title: "",
            event_date: "",
            end_date: "",
            stream_url: "",
            event_type: "other",
            tag: "",
            home_team: "",
            away_team: "",
            home_team_image: "",
            away_team_image: "",
            image_path: ""
        });
        setNewlyAddedId(null);
    };

    const handleSave = () => {
        if (editing) {
            axios.put(`/api/events/${editing.id}`, formData, {
                headers: { Authorization: `Bearer ${token}` }
            })
                .then((res) => {
                    setEvents(events.map((e) => (e.id === editing.id ? res.data : e)));
                    setEditing(null);
                    setFormData({
                        title: "",
                        event_date: "",
                        end_date: "",
                        stream_url: "",
                        event_type: "other",
                        tag: "",
                        home_team: "",
                        away_team: "",
                        home_team_image: "",
                        away_team_image: "",
                        image_path: ""
                    });
                })
                .catch((err) => console.error("Update error", err));
        } else {
            axios.post(`/api/events`, formData, {
                headers: { Authorization: `Bearer ${token}` }
            })
                .then((res) => {
                    const newEvent = res.data;
                    axios.get(`/api/events?limit=1000&page=1${filterTag ? `&tag=${encodeURIComponent(filterTag)}` : ''}${showPast ? '&showPast=true' : ''}`)
                        .then((response) => {
                            const allEvents = response.data.events.sort((a, b) => new Date(a.event_datetime) - new Date(b.event_datetime));
                            const newIndex = allEvents.findIndex(e => e.id === newEvent.id);
                            const newPage = Math.floor(newIndex / limit) + 1;
                            setPage(newPage);
                            setNewlyAddedId(newEvent.id);
                            fetchEvents();
                        });
                    setFormData({
                        title: "",
                        event_date: "",
                        end_date: "",
                        stream_url: "",
                        event_type: "other",
                        tag: "",
                        home_team: "",
                        away_team: "",
                        home_team_image: "",
                        away_team_image: "",
                        image_path: ""
                    });
                })
                .catch((err) => console.error("Add error", err));
        }
    };

    // Helper to check if event has passed.
    const isEventPassed = (event) => {
        const eventEnd = event.end_datetime ? new Date(event.end_datetime) : new Date(event.event_datetime);
        return eventEnd < new Date();
    };

    return (
        <div className="bg-dark-card p-6 rounded-lg shadow-lg">
            <h2 className="text-2xl font-bold mb-4">Event Manager</h2>
            {/* Filter Section */}
            <div className="flex flex-col md:flex-row md:items-center md:justify-between mb-4">
                <div className="flex space-x-4 items-center">
                    <label className="font-semibold">Filter by Tag:</label>
                    <select
                        value={filterTag}
                        onChange={(e) => { setFilterTag(e.target.value); setPage(1); }}
                        className="p-2 rounded bg-dark-accent"
                    >
                        <option value="">All Tags</option>
                        {TAG_OPTIONS.map((tag, idx) => (
                            <option key={idx} value={tag}>{tag}</option>
                        ))}
                    </select>
                </div>
                <div className="mt-2 md:mt-0">
                    <label className="flex items-center space-x-2">
                        <input
                            type="checkbox"
                            checked={showPast}
                            onChange={(e) => { setShowPast(e.target.checked); setPage(1); }}
                            className="form-checkbox"
                        />
                        <span className="font-semibold">Show Past Events</span>
                    </label>
                </div>
            </div>
            {/* Add New Event Button */}
            {/*<div className="mb-4">*/}
            {/*    <button onClick={handleAdd} className="bg-green-600 px-4 py-2 rounded hover:bg-green-700">*/}
            {/*        Add New Event*/}
            {/*    </button>*/}
            {/*</div>*/}
            {/* Events List */}
            <div>
                {events.map((event) => {
                    const passed = isEventPassed(event);
                    return (
                        <div
                            key={event.id}
                            className={`flex justify-between items-center p-4 border-b border-gray-700 ${newlyAddedId === event.id ? "animate-glow" : ""}`}
                        >
                            <div>
                                <h3 className="font-bold">{event.title}</h3>
                                <p className="text-sm text-gray-400">
                                    {new Date(event.event_datetime).toLocaleString()}
                                    {event.end_datetime ? ` – ${new Date(event.end_datetime).toLocaleString()}` : ""}
                                </p>
                                {event.event_type === "sports" && (
                                    <p className="text-sm text-gray-400">
                                        {event.home_team} vs {event.away_team}
                                    </p>
                                )}
                                <p className="text-sm text-gray-400">
                                    Type: {event.event_type} | Tag: {event.tag || "N/A"}
                                </p>
                                {passed && showPast && (
                                    <span className="inline-block bg-red-600 text-white px-2 py-1 rounded text-xs mt-2">
                    Passed
                  </span>
                                )}
                            </div>
                            <div className="flex space-x-2">
                                <button onClick={() => handleEdit(event)} className="bg-blue-600 px-3 py-1 rounded hover:bg-blue-700">
                                    Edit
                                </button>
                                <button onClick={() => handleDelete(event.id)} className="bg-red-600 px-3 py-1 rounded hover:bg-red-700">
                                    Delete
                                </button>
                            </div>
                        </div>
                    );
                })}
            </div>
            {/* Pagination */}
            <div className="flex justify-center mt-4 space-x-4">
                <button
                    onClick={() => setPage(page - 1)}
                    disabled={page === 1}
                    className="px-4 py-2 bg-blue-600 text-white rounded disabled:opacity-50"
                >
                    Prev
                </button>
                <button
                    onClick={() => setPage(page + 1)}
                    disabled={page === totalPages}
                    className="px-4 py-2 bg-blue-600 text-white rounded disabled:opacity-50"
                >
                    Next
                </button>
            </div>
            {/* Form to Add/Edit Event */}
            <div className="mt-6">
                <h3 className="text-xl font-bold mb-2">{editing ? "Edit Event" : "Add New Event"}</h3>
                <div className="space-y-4">
                    <div>
                        <label className="block mb-1">Title *</label>
                        <input
                            type="text"
                            value={formData.title}
                            onChange={(e) => setFormData({ ...formData, title: e.target.value })}
                            className="w-full p-3 bg-dark-accent rounded"
                        />
                    </div>
                    <div className="grid grid-cols-2 gap-4">
                        <div>
                            <label className="block mb-1">Event Date *</label>
                            <input
                                type="datetime-local"
                                value={formData.event_date}
                                onChange={(e) => setFormData({ ...formData, event_date: e.target.value })}
                                className="w-full p-3 bg-dark-accent rounded"
                            />
                        </div>
                        <div>
                            <label className="block mb-1">End Date (optional)</label>
                            <input
                                type="datetime-local"
                                value={formData.end_date}
                                onChange={(e) => setFormData({ ...formData, end_date: e.target.value })}
                                className="w-full p-3 bg-dark-accent rounded"
                            />
                        </div>
                    </div>
                    <div>
                        <label className="block mb-1">Stream URL *</label>
                        <input
                            type="text"
                            value={formData.stream_url}
                            onChange={(e) => setFormData({ ...formData, stream_url: e.target.value })}
                            className="w-full p-3 bg-dark-accent rounded"
                        />
                    </div>
                    <div className="grid grid-cols-2 gap-4">
                        <div>
                            <label className="block mb-1">Event Type *</label>
                            <select
                                value={formData.event_type}
                                onChange={(e) => setFormData({ ...formData, event_type: e.target.value })}
                                className="w-full p-3 bg-dark-accent rounded"
                            >
                                <option value="sports">Sports</option>
                                <option value="other">Other</option>
                            </select>
                        </div>
                        <div>
                            <label className="block mb-1">Tag</label>
                            <select
                                value={formData.tag}
                                onChange={(e) => setFormData({ ...formData, tag: e.target.value })}
                                className="w-full p-3 bg-dark-accent rounded"
                            >
                                <option value="">Select Tag</option>
                                {TAG_OPTIONS.map((tag, idx) => (
                                    <option key={idx} value={tag}>{tag}</option>
                                ))}
                            </select>
                        </div>
                    </div>
                    {formData.event_type === "sports" ? (
                        <>
                            <div className="grid grid-cols-2 gap-4">
                                <div>
                                    <label className="block mb-1">Home Team *</label>
                                    <input
                                        type="text"
                                        value={formData.home_team}
                                        onChange={(e) => setFormData({ ...formData, home_team: e.target.value })}
                                        className="w-full p-3 bg-dark-accent rounded"
                                    />
                                </div>
                                <div>
                                    <label className="block mb-1">Away Team *</label>
                                    <input
                                        type="text"
                                        value={formData.away_team}
                                        onChange={(e) => setFormData({ ...formData, away_team: e.target.value })}
                                        className="w-full p-3 bg-dark-accent rounded"
                                    />
                                </div>
                            </div>
                            <div className="grid grid-cols-2 gap-4">
                                <div>
                                    <label className="block mb-1">Home Team Image *</label>
                                    <div className="flex items-center space-x-4">
                                        {formData.home_team_image && (
                                            <img src={formData.home_team_image} alt="Home Team" className="w-16 h-16 object-cover rounded" />
                                        )}
                                        <button
                                            onClick={() => openFileBrowserForField("home_team_image")}
                                            className="bg-blue-600 px-3 py-1 rounded hover:bg-blue-700"
                                        >
                                            Choose Image
                                        </button>
                                        <input
                                            type="file"
                                            onChange={(e) => handleFileUpload(e, "home_team_image", "team_images")}
                                            className="bg-dark-accent p-2 rounded"
                                        />
                                    </div>
                                </div>
                                <div>
                                    <label className="block mb-1">Away Team Image *</label>
                                    <div className="flex items-center space-x-4">
                                        {formData.away_team_image && (
                                            <img src={formData.away_team_image} alt="Away Team" className="w-16 h-16 object-cover rounded" />
                                        )}
                                        <button
                                            onClick={() => openFileBrowserForField("away_team_image")}
                                            className="bg-blue-600 px-3 py-1 rounded hover:bg-blue-700"
                                        >
                                            Choose Image
                                        </button>
                                        <input
                                            type="file"
                                            onChange={(e) => handleFileUpload(e, "away_team_image", "team_images")}
                                            className="bg-dark-accent p-2 rounded"
                                        />
                                    </div>
                                </div>
                            </div>
                        </>
                    ) : (
                        <>
                            <div>
                                <label className="block mb-1">Event Image *</label>
                                <div className="flex items-center space-x-4">
                                    {formData.image_path && (
                                        <img src={formData.image_path} alt="Event" className="w-16 h-16 object-cover rounded" />
                                    )}
                                    <button
                                        onClick={() => openFileBrowserForField("image_path")}
                                        className="bg-blue-600 px-3 py-1 rounded hover:bg-blue-700"
                                    >
                                        Choose Image
                                    </button>
                                    <input
                                        type="file"
                                        onChange={(e) => handleFileUpload(e, "image_path", "events")}
                                        className="bg-dark-accent p-2 rounded"
                                    />
                                </div>
                            </div>
                        </>
                    )}
                    <div className="flex space-x-4">
                        <button onClick={handleSave} className="bg-green-600 px-4 py-2 rounded hover:bg-green-700">
                            Save
                        </button>
                        <button onClick={() => {
                            setEditing(null);
                            setFormData({
                                title: "", event_date: "", end_date: "", stream_url: "",
                                event_type: "other", tag: "", home_team: "", away_team: "",
                                home_team_image: "", away_team_image: "", image_path: ""
                            });
                        }} className="bg-gray-600 px-4 py-2 rounded hover:bg-gray-700">
                            Cancel
                        </button>
                    </div>
                </div>
            </div>
            {fileBrowserOpen && (
                <FileBrowser
                    directory={fileBrowserDir}
                    onSelect={(imgUrl) => {
                        setFormData({ ...formData, [fileBrowserField]: imgUrl });
                        setFileBrowserOpen(false);
                    }}
                    onClose={() => setFileBrowserOpen(false)}
                />
            )}
        </div>
    );
}

export default EventManager;
