import React, { useEffect } from 'react';

function BuzzsproutPlayer() {
    useEffect(() => {
        const containerId = "buzzsprout-large-player-artist-just-in-live-stream-tags-scrib";
        const container = document.getElementById(containerId);
        if (container) {
            // Clear container (in case it's been populated before)
            container.innerHTML = "";
            // Create script element
            const script = document.createElement("script");
            script.src = `https://www.buzzsprout.com/2025362.js?artist=Just+in+Live+Stream&container_id=${containerId}&player=large&tags=scrib`;
            script.async = true;
            script.charset = "utf-8";
            container.appendChild(script);
        }
    }, []);

    return (
        <div
            id="buzzsprout-large-player-artist-just-in-live-stream-tags-scrib"
            style={{ minHeight: "300px" }} // ensures the container is visible even before the player loads
        ></div>
    );
}

export default BuzzsproutPlayer;
