// import React, { useState, useEffect } from 'react';
// import axios from 'axios';
// const apiUrl = process.env.REACT_APP_API_URL || '';
//
// // FileBrowser component that displays available images.
// function FileBrowser({ directory, onSelect, onClose }) {
//     const [images, setImages] = useState([]);
//
//     useEffect(() => {
//         axios.get(`${apiUrl}/api/images?dir=${directory}`)
//             .then(res => setImages(res.data))
//             .catch(err => console.error("Error fetching images:", err));
//     }, [directory]);
//
//     return (
//         <div className="fixed inset-0 bg-black bg-opacity-70 flex items-center justify-center z-50">
//             <div className="bg-dark-card p-6 rounded-lg w-1/2">
//                 <h2 className="text-xl font-bold mb-4">Select an Image</h2>
//                 <div className="grid grid-cols-3 gap-4">
//                     {images.map((img, index) => (
//                         <div
//                             key={index}
//                             className="cursor-pointer border rounded hover:opacity-75"
//                             onClick={() => onSelect(img.url)}
//                         >
//                             <img
//                                 src={img.url}
//                                 alt={img.name}
//                                 className="w-full h-32 object-cover rounded"
//                             />
//                             <div className="text-center text-sm mt-1">{img.name}</div>
//                         </div>
//                     ))}
//                 </div>
//                 <button
//                     onClick={onClose}
//                     className="mt-4 inline-block bg-blue-600 hover:bg-blue-700 text-white px-4 py-2 rounded"
//                 >
//                     Close
//                 </button>
//             </div>
//         </div>
//     );
// }
//
// function ShowcaseManager() {
//     const [showcases, setShowcases] = useState([]);
//     const [editing, setEditing] = useState(null);
//     const [isFileBrowserOpen, setIsFileBrowserOpen] = useState(false);
//     const [availableImages, setAvailableImages] = useState([]);
//     const [formData, setFormData] = useState({ image: "", title: "", url: "" });
//
//     // Fetch showcases (public)
//     useEffect(() => {
//         axios.get(`${apiUrl}/api/showcases`)
//             .then(res => setShowcases(res.data))
//             .catch(err => console.error("Error fetching showcases", err));
//     }, []);
//
//     // Fetch images for file browser (public)
//     useEffect(() => {
//         axios.get(`${apiUrl}/api/images?dir=showcase_images`)
//             .then(res => setAvailableImages(res.data))
//             .catch(err => console.error("Error fetching images", err));
//     }, []);
//
//     // For protected endpoints, retrieve the token from localStorage.
//     const token = localStorage.getItem('token');
//
//     // File upload handler (protected)
//     const handleFileUpload = async (e, field) => {
//         const file = e.target.files[0];
//         if (file) {
//             const fd = new FormData();
//             fd.append('file', file);
//             try {
//                 const res = await axios.post(`${apiUrl}/api/upload?dir=showcase_images`, fd, {
//                     headers: {
//                         'Content-Type': 'multipart/form-data',
//                         Authorization: `Bearer ${token}`
//                     }
//                 });
//                 setFormData({ ...formData, [field]: res.data.url });
//             } catch (error) {
//                 console.error("File upload error:", error);
//             }
//         }
//     };
//
//     const handleDelete = (id) => {
//         if (window.confirm("Are you sure you want to delete this showcase?")) {
//             axios.delete(`${apiUrl}/api/showcases/${id}`, {
//                 headers: { Authorization: `Bearer ${token}` }
//             })
//                 .then(() => setShowcases(showcases.filter(item => item.id !== id)))
//                 .catch(err => console.error("Delete error", err));
//         }
//     };
//
//     const handleEdit = (showcase) => {
//         setEditing(showcase);
//         setFormData({ image: showcase.src, title: showcase.title, url: showcase.url });
//     };
//
//     const handleSave = () => {
//         if (editing) {
//             axios.put(`${apiUrl}/api/showcases/${editing.id}`, formData, {
//                 headers: { Authorization: `Bearer ${token}` }
//             })
//                 .then(res => {
//                     setShowcases(showcases.map(item => item.id === editing.id ? res.data : item));
//                     setEditing(null);
//                     setFormData({ image: "", title: "", url: "" });
//                 })
//                 .catch(err => console.error("Update error", err));
//         } else {
//             axios.post(`${apiUrl}/api/showcases`, formData, {
//                 headers: { Authorization: `Bearer ${token}` }
//             })
//                 .then(res => {
//                     setShowcases([res.data, ...showcases]);
//                     setFormData({ image: "", title: "", url: "" });
//                 })
//                 .catch(err => console.error("Add error", err));
//         }
//     };
//
//     return (
//         <div className="bg-dark-card p-6 rounded-lg shadow-lg">
//             <h2 className="text-2xl font-bold mb-4">Showcase Manager</h2>
//             <div>
//                 {showcases.map(item => (
//                     <div key={item.id} className="flex justify-between items-center p-4 border-b border-gray-700">
//                         <div className="flex items-center space-x-4">
//                             <img src={item.src} alt={item.title} className="w-16 h-16 object-cover rounded" />
//                             <div>
//                                 <h3 className="font-bold">{item.title}</h3>
//                                 <p className="text-sm text-gray-400">{item.url}</p>
//                             </div>
//                         </div>
//                         <div className="flex space-x-2">
//                             <button onClick={() => handleEdit(item)} className="bg-blue-600 px-3 py-1 rounded hover:bg-blue-700">
//                                 Edit
//                             </button>
//                             <button onClick={() => handleDelete(item.id)} className="bg-red-600 px-3 py-1 rounded hover:bg-red-700">
//                                 Delete
//                             </button>
//                         </div>
//                     </div>
//                 ))}
//             </div>
//             <div className="mt-6">
//                 <h3 className="text-xl font-bold mb-2">{editing ? "Edit Showcase" : "Add New Showcase"}</h3>
//                 <div className="space-y-4">
//                     <div>
//                         <label className="block mb-1">Image</label>
//                         <div className="flex items-center space-x-4">
//                             {formData.image && <img src={formData.image} alt="Selected" className="w-16 h-16 object-cover rounded" />}
//                             <button onClick={() => setIsFileBrowserOpen(true)} className="bg-blue-600 px-3 py-1 rounded hover:bg-blue-700">
//                                 Choose Image
//                             </button>
//                             <input
//                                 type="file"
//                                 onChange={(e) => handleFileUpload(e, "image")}
//                                 className="bg-dark-accent p-2 rounded"
//                             />
//                         </div>
//                     </div>
//                     <div>
//                         <label className="block mb-1">Title</label>
//                         <input
//                             type="text"
//                             value={formData.title}
//                             onChange={(e) => setFormData({ ...formData, title: e.target.value })}
//                             className="w-full p-3 bg-dark-accent rounded"
//                         />
//                     </div>
//                     <div>
//                         <label className="block mb-1">URL</label>
//                         <input
//                             type="text"
//                             value={formData.url}
//                             onChange={(e) => setFormData({ ...formData, url: e.target.value })}
//                             className="w-full p-3 bg-dark-accent rounded"
//                         />
//                     </div>
//                     <div className="flex space-x-4">
//                         <button onClick={handleSave} className="bg-green-600 px-4 py-2 rounded hover:bg-green-700">
//                             Save
//                         </button>
//                         <button onClick={() => {
//                             setEditing(null);
//                             setFormData({ image:"", title:"", url:"" });
//                         }} className="bg-gray-600 px-4 py-2 rounded hover:bg-gray-700">
//                             Cancel
//                         </button>
//                     </div>
//                 </div>
//             </div>
//             {isFileBrowserOpen && (
//                 <FileBrowser
//                     directory="showcase_images"
//                     onSelect={(imgUrl) => {
//                         setFormData({ ...formData, image: imgUrl });
//                         setIsFileBrowserOpen(false);
//                     }}
//                     onClose={() => setIsFileBrowserOpen(false)}
//                 />
//             )}
//         </div>
//     );
// }
//
// export default ShowcaseManager;

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { DndContext, closestCenter } from '@dnd-kit/core';
import { SortableContext, verticalListSortingStrategy, arrayMove } from '@dnd-kit/sortable';
import SortableItem from './SortableItem';
const apiUrl = process.env.REACT_APP_API_URL || '';

// FileBrowser component that displays available images.
function FileBrowser({ directory, onSelect, onClose }) {
    const [images, setImages] = useState([]);

    useEffect(() => {
        axios
            .get(`${apiUrl}/api/images?dir=${directory}`)
            .then((res) => setImages(res.data))
            .catch((err) => console.error("Error fetching images:", err));
    }, [directory]);

    return (
        <div className="fixed inset-0 bg-black bg-opacity-70 flex items-center justify-center z-50">
            <div className="bg-dark-card p-6 rounded-lg w-1/2">
                <h2 className="text-xl font-bold mb-4">Select an Image</h2>
                <div className="grid grid-cols-3 gap-4">
                    {images.map((img, index) => (
                        <div
                            key={index}
                            className="cursor-pointer border rounded hover:opacity-75"
                            onClick={() => onSelect(img.url)}
                        >
                            <img
                                src={img.url}
                                alt={img.name}
                                className="w-full h-32 object-cover rounded"
                            />
                            <div className="text-center text-sm mt-1">{img.name}</div>
                        </div>
                    ))}
                </div>
                <button
                    onClick={onClose}
                    className="mt-4 inline-block bg-blue-600 hover:bg-blue-700 text-white px-4 py-2 rounded"
                >
                    Close
                </button>
            </div>
        </div>
    );
}

function ShowcaseManager() {
    const [showcases, setShowcases] = useState([]);
    const [editing, setEditing] = useState(null);
    const [isFileBrowserOpen, setIsFileBrowserOpen] = useState(false);
    const [formData, setFormData] = useState({ image: "", title: "", url: "" });
    const token = localStorage.getItem('token');

    // Fetch showcases and ensure order_index is set.
    useEffect(() => {
        axios.get(`${apiUrl}/api/showcases`)
            .then((res) => {
                const ordered = res.data.map((item, index) => ({
                    ...item,
                    order_index: item.order_index ?? index,
                }));
                ordered.sort((a, b) => a.order_index - b.order_index);
                setShowcases(ordered);
            })
            .catch((err) => console.error("Error fetching showcases", err));
    }, []);

    // File upload handler.
    const handleFileUpload = async (e, field) => {
        const file = e.target.files[0];
        if (file) {
            const fd = new FormData();
            fd.append('file', file);
            try {
                const res = await axios.post(`${apiUrl}/api/upload?dir=showcase_images`, fd, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        Authorization: `Bearer ${token}`
                    }
                });
                setFormData({ ...formData, [field]: res.data.url });
            } catch (error) {
                console.error("File upload error:", error);
            }
        }
    };

    const handleDelete = (id, e) => {
        e.stopPropagation();
        if (window.confirm("Are you sure you want to delete this showcase?")) {
            axios.delete(`${apiUrl}/api/showcases/${id}`, {
                headers: { Authorization: `Bearer ${token}` }
            })
                .then(() => setShowcases(showcases.filter(item => item.id !== id)))
                .catch(err => console.error("Delete error", err));
        }
    };

    const handleEdit = (showcase, e) => {
        e.stopPropagation();
        setEditing(showcase);
        setFormData({ image: showcase.src, title: showcase.title, url: showcase.url });
    };

    const handleSave = () => {
        if (!formData.title.trim()) {
            alert("Title is required");
            return;
        }
        if (editing) {
            axios.put(`${apiUrl}/api/showcases/${editing.id}`, {
                ...formData,
                order_index: editing.order_index
            }, {
                headers: { Authorization: `Bearer ${token}` }
            })
                .then((res) => {
                    setShowcases(showcases.map(item => item.id === editing.id ? res.data : item));
                    setEditing(null);
                    setFormData({ image: "", title: "", url: "" });
                })
                .catch((err) => console.error("Update error", err));
        } else {
            axios.post(`${apiUrl}/api/showcases`, formData, {
                headers: { Authorization: `Bearer ${token}` }
            })
                .then((res) => {
                    const newShowcase = { ...res.data, order_index: 0 };
                    const updated = [newShowcase, ...showcases.map(item => ({ ...item, order_index: item.order_index + 1 }))];
                    setShowcases(updated);
                    setFormData({ image: "", title: "", url: "" });
                })
                .catch((err) => console.error("Add error", err));
        }
    };

    // Rearrangement handler using dnd-kit.
    const onDragEnd = (event) => {
        const { active, over } = event;
        if (!over || active.id === over.id) return;
        const oldIndex = showcases.findIndex(item => item.id.toString() === active.id);
        const newIndex = showcases.findIndex(item => item.id.toString() === over.id);
        const newOrder = arrayMove(showcases, oldIndex, newIndex).map((item, index) => ({
            ...item,
            order_index: index,
        }));
        setShowcases(newOrder);
        newOrder.forEach(item => {
            axios.put(`${apiUrl}/api/showcases/${item.id}`, {
                image: item.src,
                title: item.title,
                url: item.url,
                order_index: item.order_index
            }, {
                headers: { Authorization: `Bearer ${token}` }
            })
                .catch(err => console.error("Error updating order for showcase", item.id, err));
        });
    };

    return (
        <div className="bg-dark-card p-6 rounded-lg shadow-lg">
            <h2 className="text-2xl font-bold mb-4">Showcase Manager</h2>
            <DndContext collisionDetection={closestCenter} onDragEnd={onDragEnd}>
                <SortableContext items={showcases.map(item => item.id.toString())} strategy={verticalListSortingStrategy}>
                    {showcases.map((item) => (
                        <SortableItem key={item.id} id={item.id.toString()}>
                            <div className="flex justify-between items-center w-full">
                                <div className="flex items-center space-x-4">
                                    <img src={item.src} alt={item.title} className="w-16 h-16 object-cover rounded" />
                                    <div>
                                        <h3 className="font-bold">{item.title}</h3>
                                        <p className="text-sm text-gray-400">{item.url}</p>
                                    </div>
                                </div>
                                <div className="flex space-x-2">
                                    <button
                                        onClick={(e) => handleEdit(item, e)}
                                        className="bg-blue-600 px-3 py-1 rounded hover:bg-blue-700"
                                    >
                                        Edit
                                    </button>
                                    <button
                                        onClick={(e) => handleDelete(item.id, e)}
                                        className="bg-red-600 px-3 py-1 rounded hover:bg-red-700"
                                    >
                                        Delete
                                    </button>
                                </div>
                            </div>
                        </SortableItem>
                    ))}
                </SortableContext>
            </DndContext>
            <div className="mt-6">
                <h3 className="text-xl font-bold mb-2">{editing ? "Edit Showcase" : "Add New Showcase"}</h3>
                <div className="space-y-4">
                    <div>
                        <label className="block mb-1">Image</label>
                        <div className="flex items-center space-x-4">
                            {formData.image && (
                                <img src={formData.image} alt="Selected" className="w-16 h-16 object-cover rounded" />
                            )}
                            <button onClick={() => setIsFileBrowserOpen(true)} className="bg-blue-600 px-3 py-1 rounded hover:bg-blue-700">
                                Choose Image
                            </button>
                            <input
                                type="file"
                                onChange={(e) => handleFileUpload(e, "image")}
                                className="bg-dark-accent p-2 rounded"
                            />
                        </div>
                    </div>
                    <div>
                        <label className="block mb-1">Title *</label>
                        <input
                            type="text"
                            value={formData.title}
                            onChange={(e) => setFormData({ ...formData, title: e.target.value })}
                            className="w-full p-3 bg-dark-accent rounded"
                        />
                    </div>
                    <div>
                        <label className="block mb-1">URL</label>
                        <input
                            type="text"
                            value={formData.url}
                            onChange={(e) => setFormData({ ...formData, url: e.target.value })}
                            className="w-full p-3 bg-dark-accent rounded"
                        />
                    </div>
                    <div className="flex space-x-4">
                        <button onClick={handleSave} className="bg-green-600 px-4 py-2 rounded hover:bg-green-700">
                            Save
                        </button>
                        <button onClick={() => {
                            setEditing(null);
                            setFormData({ image: "", title: "", url: "" });
                        }} className="bg-gray-600 px-4 py-2 rounded hover:bg-gray-700">
                            Cancel
                        </button>
                    </div>
                </div>
            </div>
            {isFileBrowserOpen && (
                <FileBrowser
                    directory="showcase_images"
                    onSelect={(imgUrl) => {
                        setFormData({ ...formData, image: imgUrl });
                        setIsFileBrowserOpen(false);
                    }}
                    onClose={() => setIsFileBrowserOpen(false)}
                />
            )}
        </div>
    );
}

export default ShowcaseManager;



