import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './components/Header';
import Home from './pages/Home';
import PartnerPage from './pages/PartnerPage';
import Admin from './pages/Admin';
import Footer from './components/Footer';
import PodcastPage from './pages/PodcastPage';
import Scroll from './components/Scroll';
import ScrollToTop from './components/ScrollToTop';
import NotFound from './pages/NotFound';
import './App.css'; // Import the CSS with your animation

function App() {
    return (
        <Router>
            <Scroll />
            <div className="min-h-screen flex flex-col dark">
                <ScrollToTop />
                <Header />
                <div className="pt-20 flex-grow">
                    <Routes>
                        <Route path="/" element={<Home />} />
                        <Route path="/partner/:slug" element={<PartnerPage />} />
                        <Route path="/admin" element={<Admin />} />
                        <Route path="/podcast/:id" element={<PodcastPage />} />
                        <Route path="*" element={<NotFound />} />
                    </Routes>
                </div>
                <Footer />
            </div>
        </Router>
    );
}

export default App;
