import React from 'react';
import { useNavigate } from 'react-router-dom';

function NotFound() {
    const navigate = useNavigate();

    return (
        <div className="relative min-h-screen">
            {/* Background Image (Fixed) */}
            <div className="fixed inset-0 z-0">
                <img
                    src="/jls.png"
                    alt="Background"
                    className="w-full h-full object-cover filter blur-none brightness-50"
                />
            </div>
            {/* Main Content */}
            <div className="relative z-10 flex flex-col items-center justify-center min-h-screen text-center p-4">
                <h1 className="text-6xl font-bold text-gray-800">404</h1>
                <p className="mt-4 text-xl text-gray-300">
                    We couldn&apos;t find the page you were looking for.
                </p>
                <button
                    onClick={() => navigate('/')}
                    className="mt-6 px-6 py-3 bg-blue-600 text-white rounded hover:bg-blue-700 focus:outline-none"
                >
                    Back to Home
                </button>
            </div>
        </div>
    );
}

export default NotFound;
