import React, { useEffect, useState } from 'react';
import axios from 'axios';

function LatestYouTubeVideos() {
    const [videoIds, setVideoIds] = useState([]);
    const apiUrl = process.env.REACT_APP_API_URL || '';

    useEffect(() => {
        async function fetchVideos() {
            try {
                // Call the server endpoint that searches the entire channel.
                const response = await axios.get(`${apiUrl}/api/youtube-channel?maxResults=50`);
                // Filter for items with a valid videoId.
                const videos = response.data.items.filter(
                    item => item.id && item.id.videoId
                );
                // Sort the videos by published date (most recent first).
                videos.sort((a, b) => new Date(b.snippet.publishedAt) - new Date(a.snippet.publishedAt));
                // Take the first two video IDs.
                const ids = videos.slice(0, 2).map(item => item.id.videoId);
                setVideoIds(ids);
            } catch (error) {
                console.error('Error fetching channel videos:', error);
            }
        }
        fetchVideos();
    }, [apiUrl]);

    return (
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            {videoIds.length > 0 ? (
                videoIds.map(id => (
                    <div key={id} className="relative w-full pb-[56.25%]">
                        <iframe
                            className="absolute top-0 left-0 w-full h-full rounded-lg shadow-lg"
                            src={`https://www.youtube.com/embed/${id}`}
                            frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen
                            title="Latest YouTube Video"
                        />
                    </div>
                ))
            ) : (
                <p className="text-center text-gray-400 text-lg">Loading latest videos...</p>
            )}
        </div>
    );
}

export default LatestYouTubeVideos;
