import React, { useState, useEffect, useCallback } from 'react';

function Carousel() {
    const [showcaseImages, setShowcaseImages] = useState([]);
    const [currentIndex, setCurrentIndex] = useState(0);
    const [isFading, setIsFading] = useState(false);
    const [timer, setTimer] = useState(null);
    const apiUrl = process.env.REACT_APP_API_URL || '';

    // Fetch the carousel data on mount.
    useEffect(() => {
        async function fetchData() {
            try {
                const response = await fetch(`${apiUrl}/api/showcases`);
                if (!response.ok) {
                    const text = await response.text();
                    throw new Error(`HTTP error! status: ${response.status}, response: ${text}`);
                }
                const data = await response.json();
                setShowcaseImages(data);
            } catch (error) {
                console.error('Error fetching showcase data:', error);
            }
        }
        fetchData();
    }, [apiUrl]);

    // Timer function wrapped in useCallback to ensure stability between renders.
    const startTimer = useCallback(() => {
        const interval = setInterval(() => {
            setIsFading(true);
            setTimeout(() => {
                setCurrentIndex((prevIndex) => (prevIndex + 1) % showcaseImages.length);
                setIsFading(false);
            }, 500);
        }, 8500);
        setTimer(interval);
        return interval;
    }, [showcaseImages]);

    // Start the timer once images are loaded.
    useEffect(() => {
        if (showcaseImages.length > 0) {
            const interval = startTimer();
            return () => clearInterval(interval);
        }
    }, [showcaseImages, startTimer]);

    const goToPrevious = () => {
        setIsFading(true);
        setTimeout(() => {
            setCurrentIndex((prevIndex) =>
                prevIndex === 0 ? showcaseImages.length - 1 : prevIndex - 1
            );
            setIsFading(false);
        }, 500);
        if (timer) clearInterval(timer);
        startTimer();
    };

    const goToNext = () => {
        setIsFading(true);
        setTimeout(() => {
            setCurrentIndex((prevIndex) =>
                (prevIndex + 1) % showcaseImages.length
            );
            setIsFading(false);
        }, 500);
        if (timer) clearInterval(timer);
        startTimer();
    };

    if (showcaseImages.length === 0) {
        return (
            <div className="flex items-center justify-center h-screen">
                <svg
                    className="animate-spin h-10 w-10 text-blue-500"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                >
                    <circle
                        className="opacity-25"
                        cx="12"
                        cy="12"
                        r="10"
                        stroke="currentColor"
                        strokeWidth="4"
                    ></circle>
                    <path
                        className="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8v8z"
                    ></path>
                </svg>
            </div>
        );
    }

    return (
        <section className="min-h-screen lg:h-auto flex items-center justify-center relative">
            <div className="w-full max-w-xs sm:max-w-md md:max-w-2xl lg:max-w-2xl mx-auto px-4">
                <div className="relative aspect-square">
                    <img
                        src={showcaseImages[currentIndex].src}
                        alt={showcaseImages[currentIndex].title}
                        className={`w-full h-full object-cover rounded-lg shadow-lg transition-opacity duration-500 ${isFading ? 'opacity-0' : 'opacity-100'}`}
                    />
                    <div className="absolute bottom-0 w-full bg-dark-accent bg-opacity-80 p-4 rounded-b-lg">
                        <h3 className="text-lg sm:text-xl font-bold">
                            {showcaseImages[currentIndex].title}
                        </h3>
                        <a
                            href={showcaseImages[currentIndex].url}
                            className="text-blue-400 hover:underline text-sm sm:text-base"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            Watch Now
                        </a>
                    </div>
                </div>
                <button
                    onClick={goToPrevious}
                    className="absolute left-2 top-1/2 transform -translate-y-1/2 bg-dark-card p-2 rounded-full hover:bg-dark-accent transition-colors"
                >
                    <svg className="w-5 h-5 sm:w-6 sm:h-6" fill="none" stroke="white" viewBox="0 0 24 24">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 19l-7-7 7-7" />
                    </svg>
                </button>
                <button
                    onClick={goToNext}
                    className="absolute right-2 top-1/2 transform -translate-y-1/2 bg-dark-card p-2 rounded-full hover:bg-dark-accent transition-colors"
                >
                    <svg className="w-5 h-5 sm:w-6 sm:h-6" fill="none" stroke="white" viewBox="0 0 24 24">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5l7 7-7 7" />
                    </svg>
                </button>
            </div>
        </section>
    );
}

export default Carousel;
