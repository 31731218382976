import React from 'react';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

function SortableItem({ id, children }) {
    const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id });

    const style = {
        transform: CSS.Transform.toString(transform),
        transition,
    };

    return (
        <div ref={setNodeRef} style={style} {...attributes} className="flex items-center border-b border-gray-700 p-4">
            {/* Drag handle: only this part is draggable */}
            <div {...listeners} className="cursor-move mr-4 p-2">
                <span>☰</span>
            </div>
            {/* Item content */}
            <div className="flex-grow">
                {children}
            </div>
        </div>
    );
}

export default SortableItem;
