import React, { useState, useEffect } from 'react';
import axios from 'axios';

const apiUrl = process.env.REACT_APP_API_URL || '';

function ArchiveManager() {
    const [partners, setPartners] = useState([]);
    const [selectedPartner, setSelectedPartner] = useState(null);
    const [archives, setArchives] = useState([]);
    const [message, setMessage] = useState('');

    // Fetch all partners on mount.
    useEffect(() => {
        axios.get(`${apiUrl}/api/partners`)
            .then(res => setPartners(res.data))
            .catch(err => console.error("Error fetching partners:", err));
    }, []);

    // When a partner is selected, load its archives.
    useEffect(() => {
        if (selectedPartner) {
            setArchives(selectedPartner.archives || []);
        }
    }, [selectedPartner]);

    const handleSelectPartner = (e) => {
        const slug = e.target.value;
        const partner = partners.find(p => p.slug === slug);
        setSelectedPartner(partner);
        setMessage('');
    };

    // Handlers for Archive Years.
    const handleAddYear = () => {
        setArchives([...archives, { year: '', items: [] }]);
    };

    const handleRemoveYear = (index) => {
        const newArchives = archives.filter((_, i) => i !== index);
        setArchives(newArchives);
    };

    const handleUpdateYear = (index, newYear) => {
        const newArchives = [...archives];
        newArchives[index].year = newYear;
        setArchives(newArchives);
    };

    // Handlers for Archive Items.
    const handleAddItem = (yearIndex) => {
        const newArchives = [...archives];
        newArchives[yearIndex].items.push({ title: '', url: '' });
        setArchives(newArchives);
    };

    const handleRemoveItem = (yearIndex, itemIndex) => {
        const newArchives = [...archives];
        newArchives[yearIndex].items = newArchives[yearIndex].items.filter((_, i) => i !== itemIndex);
        setArchives(newArchives);
    };

    const handleUpdateItem = (yearIndex, itemIndex, field, value) => {
        const newArchives = [...archives];
        newArchives[yearIndex].items[itemIndex][field] = value;
        setArchives(newArchives);
    };

    const handleSaveArchives = () => {
        if (!selectedPartner) return;
        const token = localStorage.getItem('token');
        axios.put(`${apiUrl}/api/partners/slug/${selectedPartner.slug}`, { archives }, {
            headers: { Authorization: `Bearer ${token}` }
        })
            .then(res => {
                setMessage('Archives updated successfully.');
                setSelectedPartner(res.data);
            })
            .catch(err => {
                console.error("Error updating archives:", err);
                setMessage('Error updating archives.');
            });
    };

    return (
        <div className="bg-dark-card p-6 rounded-lg shadow-lg">
            <h2 className="text-2xl font-bold mb-4">Archive Manager</h2>
            <div className="mb-4">
                <h3 className="text-lg font-semibold">Select Partner</h3>
                <select
                    value={selectedPartner ? selectedPartner.slug : ''}
                    onChange={handleSelectPartner}
                    className="w-full p-2 rounded bg-dark-accent"
                >
                    <option value="">-- Select a Partner --</option>
                    {partners.map(partner => (
                        <option key={partner.id} value={partner.slug}>{partner.title}</option>
                    ))}
                </select>
            </div>
            {selectedPartner && (
                <div>
                    <h3 className="text-lg font-semibold mb-2">Archives for {selectedPartner.title}</h3>
                    {archives.map((archive, yearIndex) => (
                        <div key={yearIndex} className="mb-4 p-4 border rounded bg-gray-800">
                            <div className="flex justify-between items-center mb-2">
                                <input
                                    type="text"
                                    placeholder="Year"
                                    value={archive.year}
                                    onChange={(e) => handleUpdateYear(yearIndex, e.target.value)}
                                    className="p-2 rounded bg-dark-accent flex-grow"
                                />
                                <button onClick={() => handleRemoveYear(yearIndex)} className="ml-4 bg-red-600 px-3 py-1 rounded hover:bg-red-700">
                                    Delete Year
                                </button>
                            </div>
                            <div>
                                <h4 className="font-semibold mb-1">Items:</h4>
                                {archive.items.map((item, itemIndex) => (
                                    <div key={itemIndex} className="flex space-x-2 items-center mb-2">
                                        <input
                                            type="text"
                                            placeholder="Title"
                                            value={item.title}
                                            onChange={(e) => handleUpdateItem(yearIndex, itemIndex, 'title', e.target.value)}
                                            className="p-2 rounded bg-dark-accent flex-grow"
                                        />
                                        <input
                                            type="text"
                                            placeholder="URL"
                                            value={item.url}
                                            onChange={(e) => handleUpdateItem(yearIndex, itemIndex, 'url', e.target.value)}
                                            className="p-2 rounded bg-dark-accent flex-grow"
                                        />
                                        <button onClick={() => handleRemoveItem(yearIndex, itemIndex)} className="bg-red-600 px-3 py-1 rounded hover:bg-red-700">
                                            Delete
                                        </button>
                                    </div>
                                ))}
                                <button onClick={() => handleAddItem(yearIndex)} className="bg-green-600 px-3 py-1 rounded hover:bg-green-700">
                                    Add Item
                                </button>
                            </div>
                        </div>
                    ))}
                    <button onClick={handleAddYear} className="bg-green-600 px-4 py-2 rounded hover:bg-green-700 mb-4 mr-4">
                        Add Year
                    </button>
                    <button onClick={handleSaveArchives} className="bg-blue-600 px-4 py-2 rounded hover:bg-blue-700">
                        Save Archives
                    </button>
                </div>
            )}
            {message && <p className="mt-4 text-center text-green-400">{message}</p>}
        </div>
    );
}

export default ArchiveManager;
