import React, {useState, useEffect} from 'react';
import axios from 'axios';
import {Link, useLocation} from 'react-router-dom';
import {scroller, Element} from 'react-scroll';
import {FaPlay} from 'react-icons/fa';
import Carousel from './Carousel';
import Calendar from '../components/Calendar';

function Home() {
    const apiUrl = process.env.REACT_APP_API_URL || '';
    const [partners, setPartners] = useState([]);
    const location = useLocation();

    useEffect(() => {
        if (location.state && location.state.scrollTo) {
            setTimeout(() => {
                scroller.scrollTo(location.state.scrollTo, {
                    smooth: true,
                    offset: -80,
                    duration: 500,
                });
                // Clear the state so it doesn't trigger on subsequent loads.
                window.history.replaceState({}, document.title);
            }, 100);
        }
    }, [location]);


    useEffect(() => {
        const fetchPartners = async () => {
            try {
                const response = await axios.get(`${apiUrl}/api/partners`);
                setPartners(response.data);
            } catch (error) {
                console.error('Error fetching partners:', error);
            }
        };
        fetchPartners();
    }, []);

    const stripeButton = `
        <stripe-buy-button
          buy-button-id="buy_btn_1NxH0oDVNIqBazyqvO9yjSQV"
          publishable-key="pk_live_51Hty1xDVNIqBazyq0JFaRsikp0Z5v6qtslgpusY4oUCHAipBNYGOHUiEyhNwBCcg9IxFcJr25N84Ww3PsQm3Sipy00aXbmGnb5"
        >
        </stripe-buy-button>
    `;

    return (
        <div className="relative min-h-screen">
            {/* Background Image (Fixed) */}
            <div className="fixed inset-0 z-0">
                {/* Mobile background: visible on small screens */}
                <img
                    src="/m_background.webp"
                    alt="Mobile Background"
                    className="block md:hidden w-full h-full object-cover filter blur-none brightness-50"
                />
                {/* Desktop background: visible on md and larger screens */}
                <img
                    src="/jls.webp"
                    alt="Background"
                    className="hidden md:block w-full h-full object-cover filter blur-none brightness-50"
                />
            </div>

            {/* Main Content */}
            <div className="relative z-10">
                <Carousel/>

                {/* Calendar Section */}
                <div id="events">
                    <Calendar/>
                </div>

                <section className="container mx-auto px-4 py-12">
                    <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
                        <div
                            className="hidden lg:flex bg-dark-card rounded-lg shadow-lg items-center justify-center h-[416px] p-4">
                            <div dangerouslySetInnerHTML={{__html: stripeButton}}/>
                        </div>

                        <div className="flex flex-col gap-6">
                            <Link
                                to="/podcast/1"
                                className="bg-dark-card rounded-lg shadow-lg h-[150px] md:h-[200px] relative overflow-hidden group hover:scale-105 hover:shadow-xl transition-all duration-300 p-4"
                            >
                                <div className="w-full h-full flex items-center justify-center">
                                    <img
                                        src="/podcast-a-2.webp"
                                        alt="Podcast A"
                                        className="max-h-[152px] object-contain rounded-lg"
                                    />
                                </div>
                                <span
                                    className="absolute bottom-6 left-6 bg-blue-500 text-white px-4 py-2 flex items-center gap-2 opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                                    <FaPlay size={16}/>
                                    Watch Scrib & Gib
                                </span>
                            </Link>

                            <Link
                                to="mailto:jstrand@justinlivestream.com"
                                className="bg-dark-card rounded-lg shadow-lg h-[150px] md:h-[200px] relative overflow-hidden group hover:scale-105 hover:shadow-xl transition-all duration-300 p-4"
                            >
                                <div className="w-full h-full flex items-center justify-center">
                                    <img
                                        src="/sponsor.webp"
                                        alt="Become A Sponsor"
                                        className="max-h-[152px] object-contain rounded-lg"
                                    />
                                </div>
                                <span
                                    className="absolute bottom-6 left-6 bg-blue-500 text-white px-4 py-2 flex items-center gap-2 opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                                    <FaPlay size={16}/>
                                    Contact Us!
                                </span>
                            </Link>
                        </div>
                    </div>
                </section>

                <Element name="partners">
                    <section className="container mx-auto px-4 py-20">
                        <h2 className="text-3xl font-bold mb-8 text-center">Our Partners</h2>
                        <div id="partners" className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
                            {partners.length > 0 ? (
                                partners.map((partner) => (
                                    <Link
                                        key={partner.id}
                                        to={`/partner/${partner.slug}`}
                                        className="bg-dark-card rounded-lg p-4 shadow-lg hover:shadow-xl transition-all duration-300"
                                    >
                                        <div className="overflow-hidden rounded-lg">
                                            <img
                                                src={partner.image_url}
                                                alt={partner.title}
                                                className="w-full h-[300px] object-cover rounded-lg transform hover:scale-105 hover:brightness-110 transition-all duration-300"
                                            />
                                        </div>
                                        <h3 className="mt-2 font-bold">{partner.title}</h3>
                                        <p className="text-sm text-gray-300">{partner.description}</p>
                                    </Link>
                                ))
                            ) : (
                                <p className="text-gray-400 text-center col-span-full">No partners available.</p>
                            )}
                        </div>
                    </section>
                </Element>
            </div>
        </div>
    );
}

export default Home;
