import React, {useState, useEffect} from 'react';
import axios from 'axios';
import {FaSignOutAlt} from 'react-icons/fa';
import ShowcaseManager from '../components/ShowcaseManager';
import EventManager from '../components/EventManager';
import UserManager from '../components/UserManager';
import ProfileManager from '../components/ProfileManager';
import ArchiveManager from '../components/ArchiveManager';
import {Helmet} from 'react-helmet';

function Admin() {
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [activeTab, setActiveTab] = useState("showcases"); // Options: "showcases", "events", "users", "profile", "archives"
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [twoFaRequired, setTwoFaRequired] = useState(false);
    const [tempToken, setTempToken] = useState('');
    const [twoFaCode, setTwoFaCode] = useState('');
    const [ip, setIP] = useState('');
    const apiUrl = process.env.REACT_APP_API_URL || '';

    // Fetch user's IP address on mount.
    useEffect(() => {
        axios.get('https://api.ipify.org?format=json')
            .then(res => setIP(res.data.ip))
            .catch(err => console.error('Error fetching IP:', err));
    }, []);

    // Check for token to persist session.
    useEffect(() => {
        const token = localStorage.getItem('token');
        if (token) {
            setIsLoggedIn(true);
        }
    }, []);

    const handleLogin = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post(`${apiUrl}/api/login`, {username, password});
            if (response.data.twoFaRequired) {
                // If 2FA is required, store the temporary token and prompt for TOTP code.
                setTwoFaRequired(true);
                setTempToken(response.data.token);
            } else {
                localStorage.setItem('token', response.data.token);
                setIsLoggedIn(true);
            }
        } catch (error) {
            alert('Login failed');
        }
    };

    const handleVerify2FA = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post(`${apiUrl}/api/login/verify-2fa`, {token: twoFaCode}, {
                headers: {Authorization: `Bearer ${tempToken}`}
            });
            localStorage.setItem('token', response.data.token);
            setIsLoggedIn(true);
            setTwoFaRequired(false);
            setTempToken('');
            setTwoFaCode('');
        } catch (error) {
            alert('2FA verification failed');
        }
    };

    const handleLogout = () => {
        localStorage.removeItem('token');
        setIsLoggedIn(false);
    };

    if (!isLoggedIn) {
        return (
            <>
                <Helmet>
                    <meta name="robots" content="noindex"/>
                    <title>Admin Dashboard &#8226; Justin Live Stream</title>
                </Helmet>
                <div className="min-h-screen flex items-center justify-center">
                    <div className="bg-dark-card p-8 rounded-lg shadow-lg w-full max-w-md">
                        <img src="/logo.webp" alt="Logo" className="h-24 mx-auto mb-6"/>
                        {!twoFaRequired ? (
                            <form onSubmit={handleLogin}>
                                <input
                                    type="text"
                                    placeholder="Username"
                                    value={username}
                                    onChange={(e) => setUsername(e.target.value)}
                                    className="w-full p-3 mb-4 bg-dark-accent rounded"
                                />
                                <input
                                    type="password"
                                    placeholder="Password"
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                    className="w-full p-3 mb-6 bg-dark-accent rounded"
                                />
                                <button type="submit"
                                        className="w-full p-3 bg-blue-600 rounded hover:bg-blue-700 transition-colors">
                                    Login
                                </button>
                            </form>
                        ) : (
                            <form onSubmit={handleVerify2FA}>
                                <p className="mb-4 text-center">Enter your 2FA code</p>
                                <input
                                    type="text"
                                    placeholder="2FA Code"
                                    value={twoFaCode}
                                    onChange={(e) => setTwoFaCode(e.target.value)}
                                    className="w-full p-3 mb-4 bg-dark-accent rounded"
                                />
                                <button type="submit"
                                        className="w-full p-3 bg-blue-600 rounded hover:bg-blue-700 transition-colors">
                                    Verify 2FA
                                </button>
                            </form>
                        )}
                        <div className="mt-4 p-4 border border-gray-700 rounded bg-gray-800">
                            <p className="text-sm text-gray-400">
                                Your IP address: <span className="text-white">{ip || 'Fetching IP...'}</span>
                            </p>
                            <p className="mt-2 text-sm text-gray-400">
                                Authorized personnel only. All access attempts are logged and investigated.
                            </p>
                        </div>
                    </div>
                </div>
            </>
        );
    }

    return (
        <>
            <Helmet>
                <meta name="robots" content="noindex"/>
            </Helmet>
            <div className="container mx-auto px-4 py-20">
                <div className="flex justify-between items-center mb-8">
                    <h1 className="text-3xl font-bold">Admin Dashboard</h1>
                    <button onClick={handleLogout}
                            className="flex items-center space-x-2 bg-red-600 hover:bg-red-700 text-white px-4 py-2 rounded">
                        <FaSignOutAlt className="w-5 h-5"/>
                        <span>Logout</span>
                    </button>
                </div>
                {/* Tab Navigation */}
                <div className="mb-8 border-b border-gray-700">
                    <div className="flex justify-between">
                        <div className="flex space-x-4">
                            <button
                                onClick={() => setActiveTab("showcases")}
                                className={`pb-2 ${activeTab === "showcases" ? "border-b-2 border-blue-500" : ""}`}
                            >
                                Showcase Manager
                            </button>
                            <button
                                onClick={() => setActiveTab("events")}
                                className={`pb-2 ${activeTab === "events" ? "border-b-2 border-blue-500" : ""}`}
                            >
                                Event Manager
                            </button>
                            <button
                                onClick={() => setActiveTab("users")}
                                className={`pb-2 ${activeTab === "users" ? "border-b-2 border-blue-500" : ""}`}
                            >
                                User Manager
                            </button>
                            <button
                                onClick={() => setActiveTab("archives")}
                                className={`pb-2 ${activeTab === "archives" ? "border-b-2 border-blue-500" : ""}`}
                            >
                                Archive Manager
                            </button>
                        </div>
                        <div className="flex space-x-4">
                            <button
                                onClick={() => setActiveTab("profile")}
                                className={`pb-2 ${activeTab === "profile" ? "border-b-2 border-blue-500" : ""}`}
                            >
                                Profile
                            </button>
                        </div>
                    </div>
                </div>
                <div>
                    {activeTab === "showcases" && <ShowcaseManager/>}
                    {activeTab === "events" && <EventManager/>}
                    {activeTab === "users" && <UserManager/>}
                    {activeTab === "profile" && <ProfileManager/>}
                    {activeTab === "archives" && <ArchiveManager/>}
                </div>
            </div>
        </>
    );
}

export default Admin;
