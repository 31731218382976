import React from 'react';
import BuzzsproutPlayer from '../components/BuzzsproutPlayer';
import LatestYouTubeVideos from '../components/LatestYouTubeVideos';
import {Helmet} from "react-helmet";

function PodcastPage() {
    return (
        <>
            <Helmet>
                <title>Scrib & Gib &#8226; Justin Live Stream</title>
                <meta name="description" content="A podcast for Life, Family, and Sports. Listen to Brian Scribner and Travis Gibson sort it all out!"/>
            </Helmet>
            <div className="min-h-screen relative text-white">
                {/* Background Image (Fixed) */}
                <div className="fixed inset-0 z-0">
                    {/* Mobile background: visible on small screens */}
                    <img
                        src="/partner_banners/m_SNG_background.webp"
                        alt="Mobile Background"
                        className="block md:hidden w-full h-full object-cover filter blur-none brightness-50"
                    />
                    {/* Desktop background: visible on md and larger screens */}
                    <img
                        src="/partner_banners/sng_old_bg.webp"
                        alt="Background"
                        className="hidden md:block w-full h-full object-cover filter blur-none brightness-50"
                    />
                </div>

                {/* Header Section with static color overlay */}
                <header className="relative z-10 bg-gray-900 bg-opacity-75 py-12 flex items-center justify-center">
                    <h1 className="text-4xl font-bold">Scrib & Gib Podcast</h1>
                </header>

                <main className="relative z-10 container mx-auto px-4 py-16">
                    {/* Buzzsprout Player Section */}
                    <section className="mb-16">
                        <div className="bg-dark-card p-6 rounded-lg shadow-lg">
                            <BuzzsproutPlayer/>
                        </div>
                    </section>

                    {/* YouTube Channel Section */}
                    <section className="mb-16">
                        <div className="bg-dark-card p-6 rounded-lg shadow-lg flex flex-col items-center">
                            <h2 className="text-2xl font-bold mb-4">Watch More on YouTube</h2>
                            <p className="mb-4 text-gray-300 text-center">
                                Check out our latest videos and playlists on our YouTube channel.
                            </p>
                            <a
                                href="https://www.youtube.com/@scribandgib"
                                target="_blank"
                                rel="noopener noreferrer"
                                className="inline-block bg-blue-600 hover:bg-blue-700 text-white font-semibold py-3 px-6 rounded-full transition duration-300"
                            >
                                Visit Our YouTube Channel
                            </a>
                        </div>
                    </section>

                    {/* Latest YouTube Videos Section */}
                    <section className="mb-16">
                        <div className="bg-dark-card p-6 rounded-lg shadow-lg">
                            <h2 className="text-2xl font-bold mb-4">Latest YouTube Videos</h2>
                            <LatestYouTubeVideos/>
                        </div>
                    </section>
                </main>
            </div>
        </>
    );
}

export default PodcastPage;
