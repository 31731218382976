import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { FaLock, FaUserShield } from 'react-icons/fa';

const apiUrl = process.env.REACT_APP_API_URL || '';

function ProfileManager() {
    const [profile, setProfile] = useState(null);
    const [formData, setFormData] = useState({ username: '', password: '' });
    const [qrCode, setQrCode] = useState('');
    const [twoFaCode, setTwoFaCode] = useState('');
    const [message, setMessage] = useState('');
    const token = localStorage.getItem('token');

    // Fetch profile on mount.
    useEffect(() => {
        axios
            .get(`${apiUrl}/api/profile`, {
                headers: { Authorization: `Bearer ${token}` }
            })
            .then(res => {
                setProfile(res.data);
                setFormData({ username: res.data.username, password: '' });
            })
            .catch(err => console.error("Error fetching profile:", err));
    }, [token]);

    const handleUpdate = () => {
        axios
            .put(`${apiUrl}/api/profile`, formData, {
                headers: { Authorization: `Bearer ${token}` }
            })
            .then(res => {
                setProfile(res.data);
                setMessage('Profile updated successfully.');
            })
            .catch(err => {
                console.error("Error updating profile:", err);
                setMessage('Error updating profile.');
            });
    };

    const handleEnable2FA = () => {
        axios
            .post(`${apiUrl}/api/profile/enable-2fa`, {}, {
                headers: { Authorization: `Bearer ${token}` }
            })
            .then(res => {
                setQrCode(res.data.qrCode);
                setMessage('Scan the QR code with your authenticator app and enter the code below.');
            })
            .catch(err => {
                console.error("Error enabling 2FA:", err);
                setMessage('Error enabling 2FA.');
            });
    };

    const handleVerify2FA = () => {
        axios
            .post(`${apiUrl}/api/profile/verify-2fa`, { token: twoFaCode }, {
                headers: { Authorization: `Bearer ${token}` }
            })
            .then(res => {
                setMessage(res.data.message);
                // Refresh profile to update 2FA status.
                axios
                    .get(`${apiUrl}/api/profile`, { headers: { Authorization: `Bearer ${token}` } })
                    .then(res => setProfile(res.data));
            })
            .catch(err => {
                console.error("Error verifying 2FA:", err);
                setMessage('Invalid 2FA code.');
            });
    };

    const handleDisable2FA = () => {
        axios
            .post(`${apiUrl}/api/profile/disable-2fa`, {}, {
                headers: { Authorization: `Bearer ${token}` }
            })
            .then(res => {
                setMessage(res.data.message || '2FA disabled successfully.');
                // Refresh profile to update 2FA status.
                axios
                    .get(`${apiUrl}/api/profile`, { headers: { Authorization: `Bearer ${token}` } })
                    .then(res => setProfile(res.data));
            })
            .catch(err => {
                console.error("Error disabling 2FA:", err);
                setMessage('Error disabling 2FA.');
            });
    };

    if (!profile) return <div>Loading profile...</div>;

    return (
        <div className="bg-dark-card p-6 rounded-lg shadow-lg">
            <h2 className="text-2xl font-bold mb-4 flex items-center gap-2">
                <FaLock /> Profile Settings
            </h2>
            {message && <p className="mb-4 text-green-400">{message}</p>}
            <div className="space-y-4">
                <div>
                    <label className="block mb-1">Username</label>
                    <input
                        type="text"
                        value={formData.username}
                        onChange={(e) => setFormData({ ...formData, username: e.target.value })}
                        className="w-full p-3 bg-dark-accent rounded"
                    />
                </div>
                <div>
                    <label className="block mb-1">Password (Leave blank if not changing)</label>
                    <input
                        type="password"
                        value={formData.password}
                        onChange={(e) => setFormData({ ...formData, password: e.target.value })}
                        className="w-full p-3 bg-dark-accent rounded"
                    />
                </div>
                <button onClick={handleUpdate} className="bg-blue-600 px-4 py-2 rounded hover:bg-blue-700">
                    Update Profile
                </button>
            </div>

            <hr className="my-6 border-gray-700" />

            <h2 className="text-2xl font-bold mb-4 flex items-center gap-2">
                <FaUserShield /> Two-Factor Authentication
            </h2>
            {!profile.is_2fa_enabled ? (
                <>
                    <button onClick={handleEnable2FA} className="bg-blue-600 px-4 py-2 rounded hover:bg-blue-700">
                        Enable 2FA
                    </button>
                    {qrCode && (
                        <div className="mt-4">
                            <p className="mb-2">Scan this QR code with your authenticator app:</p>
                            <img src={qrCode} alt="2FA QR Code" className="w-48 h-48" />
                            <div className="mt-4">
                                <label className="block mb-1">Enter Code</label>
                                <input
                                    type="text"
                                    value={twoFaCode}
                                    onChange={(e) => setTwoFaCode(e.target.value)}
                                    className="w-full p-3 bg-dark-accent rounded"
                                />
                                <button onClick={handleVerify2FA} className="bg-green-600 mt-2 px-4 py-2 rounded hover:bg-green-700">
                                    Verify 2FA
                                </button>
                            </div>
                        </div>
                    )}
                </>
            ) : (
                <>
                    <p className="text-green-400 mb-4">Two-Factor Authentication is enabled.</p>
                    <button onClick={handleDisable2FA} className="bg-red-600 px-4 py-2 rounded hover:bg-red-700">
                        Disable 2FA
                    </button>
                </>
            )}
        </div>
    );
}

export default ProfileManager;
