import React, { useState, useEffect } from 'react';
import axios from 'axios';
const apiUrl = process.env.REACT_APP_API_URL || '';

function UserManager() {
    const [users, setUsers] = useState([]);
    const [newUser, setNewUser] = useState({ username: '', password: '' });
    // Retrieve the token from localStorage for protected endpoints.
    const token = localStorage.getItem('token');

    // Fetch all users on mount.
    const fetchUsers = () => {
        axios.get(`${apiUrl}/api/users`, {
            headers: { Authorization: `Bearer ${token}` }
        })
            .then(res => setUsers(res.data))
            .catch(err => console.error("Error fetching users:", err));
    };

    useEffect(() => {
        fetchUsers();
    }, []);

    const handleAddUser = () => {
        if (!newUser.username || !newUser.password) {
            alert("Please provide both username and password");
            return;
        }
        axios.post(`${apiUrl}/api/users`, newUser, {
            headers: { Authorization: `Bearer ${token}` }
        })
            .then(res => {
                setUsers([...users, res.data]);
                setNewUser({ username: '', password: '' });
            })
            .catch(err => console.error("Error adding user:", err));
    };

    const handleDeleteUser = (id) => {
        if (window.confirm("Are you sure you want to delete this user?")) {
            axios.delete(`${apiUrl}/api/users/${id}`, {
                headers: { Authorization: `Bearer ${token}` }
            })
                .then(() => {
                    setUsers(users.filter(user => user.id !== id));
                })
                .catch(err => console.error("Error deleting user:", err));
        }
    };

    return (
        <div className="bg-dark-card p-6 rounded-lg shadow-lg">
            <h2 className="text-2xl font-bold mb-4">User Manager</h2>
            <div className="mb-4">
                <h3 className="text-lg font-semibold">Add New User</h3>
                <div className="flex space-x-4 mt-2">
                    <input
                        type="text"
                        placeholder="Username"
                        value={newUser.username}
                        onChange={(e) => setNewUser({ ...newUser, username: e.target.value })}
                        className="p-2 rounded bg-dark-accent"
                    />
                    <input
                        type="password"
                        placeholder="Password"
                        value={newUser.password}
                        onChange={(e) => setNewUser({ ...newUser, password: e.target.value })}
                        className="p-2 rounded bg-dark-accent"
                    />
                    <button
                        onClick={handleAddUser}
                        className="bg-green-600 hover:bg-green-700 text-white px-4 py-2 rounded"
                    >
                        Add
                    </button>
                </div>
            </div>
            <div>
                <h3 className="text-lg font-semibold mb-2">Existing Users</h3>
                {users.length > 0 ? (
                    <ul>
                        {users.map(user => (
                            <li key={user.id} className="flex justify-between items-center border-b border-gray-700 py-2">
                                <span>{user.username}</span>
                                <button
                                    onClick={() => handleDeleteUser(user.id)}
                                    className="bg-red-600 hover:bg-red-700 text-white px-3 py-1 rounded"
                                >
                                    Delete
                                </button>
                            </li>
                        ))}
                    </ul>
                ) : (
                    <p className="text-gray-400">No users available.</p>
                )}
            </div>
        </div>
    );
}

export default UserManager;
