import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Element, scroller } from 'react-scroll';
import { MdLiveTv } from "react-icons/md";

function Calendar({ filterTag }) {
    const [events, setEvents] = useState([]);
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const limit = 4;
    const apiUrl = process.env.REACT_APP_API_URL || '';

    useEffect(() => {
        const fetchEvents = async () => {
            try {
                let url = `${apiUrl}/api/events?limit=${limit}&page=${page}`;
                if (filterTag) {
                    url += `&tag=${encodeURIComponent(filterTag)}`;
                }
                const res = await axios.get(url);
                setEvents(res.data.events);
                setTotalPages(res.data.totalPages);
            } catch (error) {
                console.error('Error fetching events:', error);
            }
        };
        fetchEvents();
    }, [page, filterTag, apiUrl]);

    const handlePageChange = (newPage) => {
        setPage(newPage);
        scroller.scrollTo('events', {
            smooth: true,
            offset: -100,
            duration: 500,
        });
    };

    // Helper function to format date and time as "Month Day, Year • Time"
    const formatDateTime = (dateString) => {
        const date = new Date(dateString);
        const formattedDate = date.toLocaleDateString('en-US', {
            month: 'long',
            day: 'numeric',
            year: 'numeric',
        });
        const formattedTime = date.toLocaleTimeString('en-US', {
            hour: 'numeric',
            minute: 'numeric',
            hour12: true,
        }).toLowerCase();
        return `${formattedDate} • ${formattedTime}`;
    };

    // Helper function to format only the date as "Month Day, Year"
    const formatDate = (dateString) => {
        const date = new Date(dateString);
        return date.toLocaleDateString('en-US', {
            month: 'long',
            day: 'numeric',
            year: 'numeric',
        });
    };

    return (
        <Element name="events">
            <section className="relative py-12 text-white">
                <div className="container mx-auto px-4">
                    <h2 className="text-3xl font-bold mb-8 text-center">Upcoming Events</h2>
                    {events.length === 0 ? (
                        <div className="text-center py-10">
                            <p className="text-2xl font-semibold text-gray-400">
                                There are no upcoming events.
                            </p>
                        </div>
                    ) : (
                        <>
                            <div>
                                {events.map((event) => (
                                    <div
                                        key={event.id}
                                        className="relative overflow-hidden transition duration-300 transform hover:scale-105 hover:shadow-lg mb-6 last:mb-0 rounded-lg"
                                    >
                                        {/* Diagonal Background with Smooth Transition */}
                                        <div
                                            className="absolute inset-0"
                                            style={{
                                                background: "linear-gradient(135deg, #1f2937 40%, #374151 60%)"
                                            }}
                                        ></div>

                                        <div className="relative flex flex-wrap py-14 px-12 z-10">
                                            {/* Text Column */}
                                            <div className="w-full md:w-7/12">
                                                <div>
                                                    <div className="text-blue-500 text-sm uppercase">
                                                        {event.tag ? event.tag : (event.event_type === 'sports' ? 'Sports' : 'Event')}
                                                    </div>
                                                    <h2 className="text-white text-3xl font-bold uppercase mt-4">
                                                        <a href={event.stream_url} target="_blank" rel="noopener noreferrer">
                                                            {event.title}
                                                        </a>
                                                    </h2>
                                                    {event.end_datetime ? (
                                                        <div className="text-gray-400 text-sm uppercase mt-4 text-center md:text-left">
                                                            {formatDate(event.event_datetime)} – {formatDate(event.end_datetime)}
                                                        </div>
                                                    ) : (
                                                        <div className="text-gray-400 text-sm uppercase mt-4 text-center md:text-left">
                                                            {formatDateTime(event.event_datetime)}
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                            {/* Image Column */}
                                            <div className="w-full md:w-5/12 mt-6 md:mt-0">
                                                <div className="flex justify-around items-center">
                                                    {event.event_type === 'sports' ? (
                                                        <>
                                                            <div className="flex flex-col items-center">
                                                                <img
                                                                    src={event.home_team_image}
                                                                    alt={event.home_team}
                                                                    className="w-16 h-16 object-cover rounded-full"
                                                                />
                                                                {/* Use fixed width with truncate for long names */}
                                                                <span className="mt-2 block w-20 text-center">
                                                                    {event.home_team}
                                                                </span>
                                                            </div>
                                                            <span className="text-xl font-bold">vs</span>
                                                            <div className="flex flex-col items-center">
                                                                <img
                                                                    src={event.away_team_image}
                                                                    alt={event.away_team}
                                                                    className="w-16 h-16 object-cover rounded-full"
                                                                />
                                                                <span className="mt-2 block w-20 text-center">
                                                                    {event.away_team}
                                                                </span>
                                                            </div>
                                                        </>
                                                    ) : (
                                                        <div>
                                                            <img
                                                                src={event.image_path}
                                                                alt={event.title}
                                                                className="w-full h-52 object-cover rounded-lg"
                                                            />
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                        {/* Watch Stream Button */}
                                        <div className="absolute top-4 right-4 z-10">
                                            <a
                                                href={event.stream_url}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                className="flex items-center justify-center p-2 bg-blue-500 hover:bg-blue-600 rounded-full transition duration-300"
                                            >
                                                <MdLiveTv size={24} className="text-white" />
                                            </a>
                                        </div>
                                    </div>
                                ))}
                            </div>
                            {/* Pagination */}
                            <div className="flex justify-center mt-8 space-x-4">
                                <button
                                    onClick={() => handlePageChange(page - 1)}
                                    disabled={page === 1}
                                    className="px-4 py-2 bg-blue-500 text-white rounded disabled:opacity-50"
                                >
                                    Prev
                                </button>
                                <button
                                    onClick={() => handlePageChange(page + 1)}
                                    disabled={page === totalPages}
                                    className="px-4 py-2 bg-blue-500 text-white rounded disabled:opacity-50"
                                >
                                    Next
                                </button>
                            </div>
                        </>
                    )}
                </div>
            </section>
        </Element>
    );
}

export default Calendar;
